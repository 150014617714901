<template>
<main class="app-list">
  <h1>Приложения для пациентов и врачей</h1>
  <p v-if="status">{{ status }}</p>
    <div v-if="apps">
      <article class="app-card" v-for="app in apps" v-bind:key="app['package']">
        <h2 class="app-card-heading">
          <img class="app-icon" width="40" height="40"
               v-if="app['iconUrl']"
               v-bind:src="app['iconUrl']"
               v-bind:alt="'Эмблема ' + app['title']"
               v-bind:title="'Эмблема ' + app['title']">
          <span>{{ app['title'] }}</span>
        </h2>
        <p>{{ app['description'] }}</p>
        <div class="stores-row">
          <div class="store-block"
               v-for="store in stores.filter(it => it in app['stores'])"
               v-bind:key="store">
            <span v-if="app['stores'][store]['qrCodeUrl']">
              <img width="200" height="200"
                   v-bind:src="getQrCodeSrc(app['stores'][store]['qrCodeUrl'])"
                   v-bind:alt="'QR-код на ' + app['title'] + ' в ' + store"
                   v-bind:title="'QR-код на ' + app['title'] + ' в ' + store">
            </span>
            <span v-if="app['stores'][store]['buttonUrl']">
              <a v-bind:href="app['stores'][store]['buttonUrl']"
                 v-bind:download="app['stores'][store]['download']"
                 target="_blank">
                <img height="40"
                     v-bind:src="'/img/buttons/' + store + '.svg'"
                     v-bind:alt="'Скачать ' + app['title'] + ' из ' + store"
                     v-bind:title="'Скачать ' + app['title'] + ' из ' + store">
              </a>
            </span>
          </div>
        </div>
      </article>
    </div>
</main>
</template>

<script>
export default {
  name: 'AppList',
  data() {
    return {
      apps: null,
      status: null,
      stores: [
        'App Store',
        'Google Play',
        'RuStore',
        'F-Droid',
        'Medsenger Storefront',
      ],
    }
  },
  created() {
    this.fetchApps()
  },
  methods: {
    fetchApps() {
      fetch('/data/apps.json')
        .then(resp => resp.json())
        .then(apps => {
          this.apps = apps
          this.status = null
        })
        .catch(error => this.status = error)
    },
    getQrCodeSrc(url) {
      return 'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=' + encodeURI(url)
    },
  },
}
</script>

<style scoped>
main {
  max-width: 768px;
  margin: 0 auto;
}
.app-card {
  margin: 64px auto;
}
.app-card-heading {
  display: flex;
  gap: 8px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.app-icon {
  border-radius: 20%;
}
.stores-row {
  display: flex;
  gap: 16px;
  row-gap: 32px;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.store-block {
  display: flex;
  gap: 8px;
  flex-flow: column nowrap;
  align-items: center;
}
</style>
